.generate-marg(100);
.generate-marg(@nu, @in: -30) when (@in =< @nu) {
  .marg-v-@{in} {
    margin-top: (@in * 100px / @nu) !important;
		margin-bottom: (@in * 100px / @nu) !important;
  }
	.marg-h-@{in} {
    margin-left: (@in * 100px / @nu) !important;
		margin-right: (@in * 100px / @nu) !important;
  }
	.padding-v-@{in} {
    padding-top: (@in * 100px / @nu) !important;
		padding-bottom: (@in * 100px / @nu) !important;
  }
	.padding-h-@{in} {
    padding-left: (@in * 100px / @nu) !important;
		padding-right: (@in * 100px / @nu) !important;
  }
	
	.opacity-@{in} {
    opacity:  (@in/100) !important;
  }
	
	.col-w-@{in}{
		width: (@in * 100% / @nu);
	}
	.col-wpx-@{in}{
		width: (@in * 100px / @nu);
	}
	
	.max-vh-@{in}{
		@media(min-width:@screen-sm-min){
			max-height: (@in * 100vh / @nu);
			overflow: auto;
		}
	} 
	
	
  .generate-marg(@nu, (@in + 5));  
}
*[class*="marg-v-"]{
	&.marg-bottom-0{margin-bottom:0 !important;}
	&.marg-top-0{margin-top:0 !important;}
}
*[class*="marg-h-"]{
	&.marg-right-0{margin-right:0 !important;}
	&.marg-left-0{margin-left:0 !important;}
}
.marg-h-auto{
	float: none;
	margin-left: auto; margin-right: auto;
}

*[class*="padding-v-"]{
	&.padding-bottom-0{padding-bottom:0 !important;}
	&.padding-top-0{padding-top:0 !important;}
}
*[class*="padding-h-"]{
	&.padding-right-0{padding-right:0 !important;}
	&.padding-left-0{padding-left:0 !important;}
}