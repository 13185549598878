.navbar-default {
  background-color: #16294c;
  border-color: #16294c;
	border-radius: 0;
	.navbar-brand{
		font-weight: 600;
		font-size: 16px;
		background: #294a89 !important;
		&:hover, &:active, &:focus{
			background: lighten(#294a89, 5%) !important;
		}
	}	//	/.navbar-brand
	li{
		&.active{
			background: darken(#294a89, 10%) !important;
		}
		
	}
	a{
		cursor: pointer;
		transition: .2s ease all;
		color: #fff !important; 
		background: none !important;
		&:hover, &:active, &:focus{
			background: darken(#294a89, 5%) !important;
		}
	}
	
}	//	/navbar-default