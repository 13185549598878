@import "FontAwesome/fontawesome"; 
@import "FontAwesome/solid";
@import "FontAwesome/regular";
@import "FontAwesome/brands";
@import "FontAwesome/v4-shims"; 

@import "bootstrap/bootstrap-3.3.7/less/bootstrap.less";
@import "varaibles-override"; 
@import "mixins-override"; 

@import url('https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i,900,900i&subset=latin-ext');

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
@keyframes slideinRow {
    from { min-height: 0px }
    to   { min-height: @grid-gutter-width*1.3333; }
}

html, body{
	height: 100%;
}
body{
	font-family: 'Lato', sans-serif;
}

@media(max-width:@screen-sm-max){
	.container{
		width:100%;
	}
}

@import "components/navbar";

.display{
	&-block{display: block};
	&-inline-block{display: inline-block};
	&-table{display: table};
}

app-services-landing{
	
	img{
		//width: auto !important; 
		display: table; margin: 0 auto;
		width: 58.33333333% !important;
	}
}	//	/app-services-landing  

app-signin{
	form{
		max-width: 250px;
		margin: 0 auto;
		button{
			display: table !important;
			margin: 50px auto;
		}
	}	//	/form
}	//	/app-signin

app-retention-manager{
	@tileHeghtBase : 225px;
	img{
		max-width: 100%;
		max-height: 100%;
	}
	.dashboard-container{
		//height: auto;
		height: @tileHeghtBase;
		@media(max-width:@screen-sm-max){
			height: @tileHeghtBase*0.6666;
			.dashboard-content a{font-size: 18px;}
		}
		display: flex;
		flex-direction: column;
		margin-bottom: @grid-gutter-width;
		.dashboard-content{
			margin-bottom: 0;
			flex: 1 1 auto;
			display: flex;
			justify-content: flex-end;
			.list-group-item-text{
				display: flex;
				flex: 1 1 auto;
			}
			a{
				transition: .4s ease all;
				align-items: center;
    		justify-content: center;
				display: flex;
				text-decoration: none;
				>span{
					line-height: 1;
				}
			}
			> img{
				height: auto;
			}
			
		}	//	/.dashboard-content
		
	}	//	/.dashboard-container
		.chart-container{
			.dashboard-content{
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: flex-end;
				margin: 0;
				img{
					height: auto !important;
				}
			}	
		}
		.chart-container, .stats-container{
			min-height: @tileHeghtBase*1.35555;
			margin-bottom: @grid-gutter-width;
			display: flex;
    	flex-direction: column;
			.dashboard-content{
				flex: 1 1 100%;
				margin: 0; 
			}
			&.stats-container{
				display: flex;
				flex-direction: column;
				justify-content: center;
				.dashboard-content{
					display: flex;
					flex-direction: column;
					justify-content: center
				}	//	/.dashboard-content
			}	//	/.stats-container
			@media(max-width:@screen-sm-max){
				height: @tileHeghtBase*0.666;
				min-height: @tileHeghtBase*1.0625;
				.dashboard-content{
					font-size: 18px;
					p{
						font-size: inherit;
						margin: 10px;
						
					}
					a{font-size: inherit;}
				}
			}
			
		}		//	/.chart-container, .stats-container
}	//	/app-retention-manager

/*.mat-table{
	font-family: 'Lato', sans-serif;
	font-size: 16px !important;
	margin: @grid-gutter-width/2 0 @grid-gutter-width; 
	animation: fadein;
	animation-duration: .5s;
	animation-delay: .2s;
	animation-fill-mode: forwards;
	.mat-header-row, .mat-row{
		padding: 0;
		border: 0;
		//min-height: @grid-gutter-width*1.3333;
		animation: slideinRow;
		animation-duration: .5s;
		animation-delay: .2s;  
		animation-fill-mode: forwards;
		overflow: hidden;
	}
	.mat-header-cell{
		font-weight: 600 !important;
		font-size: inherit;
	}
	.mat-cell{
		font-size: inherit;
		a{
			display: inline-block;
			+ a{
				margin-left: @grid-gutter-width/2;
			}
		}
		
	}	//	/.mat-cell
	.mat-cell, .mat-header-cell{
		&:not(:last-child){
			padding-right: 10px;
		}
	}
}	//	/mat-table */


h3.component-title{
	margin-top: @grid-gutter-width*0.6666;
	@media(max-width:@screen-sm-max){
		margin-top:@grid-gutter-width/3;
	}
}


nav{
	&.navbar-default{
		li.dropdown{
			.dropdown-toggle{
				padding-top: 7px;
				padding-bottom: 7px;
				
			}
			.dropdown-menu{
				border-radius: 0;
				a{
					color: @text-color !important;
					&:hover{
						color: #fff !important;
					}
				}
			}
		}
	}
}

.dashboard-row{
	display: flex; 
	.dashboard-item{
		display: inline-flex; flex-direction: column;
		>*{
			border-radius: 0 !important;
		}
		.dashboard-title{
			background: #f5f5f5;
		}
		.dashboard-content{
			flex: 1 1 auto;
			padding: 0;
			display: inline-flex;
			a{
				flex: 1 1 auto;
				display: flex; align-items: flex-end; justify-content: center;
				padding: @grid-gutter-width/2;
				transition: .3s ease all;
				img{
					max-width: 100%;
					max-height: 100%;
					height: auto;
				}
				&:hover, &:active, &:focus{
					background: #ddd;
				}
			}	//	/a
			
			.ngx-charts{
				max-width: 100%;
				margin: 0 auto;
			}
		}	//	/.dashboard-content

	}	//	/.dashboard-item
	
}


.table{
	&-borderless{
		border: 0;
		caption{
			vertical-align: middle;
		}
		>tbody,>tfoot, >thead{
			>tr{
				>td,
				>th{
					border: 0;
					padding: 5px 8px;
					
					&:first-child{
						padding-left: 0;
					}
					&:last-child{
						padding-right: 0;
					}
					.table-v-align-center&{
						vertical-align: middle;
					}
				}
				>th{
					font-size: .9em;
					font-weight: 900;
				}
			}
		}
		
	}
	tr.bordered{
		>th, td{
			border-bottom: 2px solid #ddd;
		}
		& + tr{
			>td, > th{
				padding-top: 8px;
			}
		}
	}
	&-fixed{
		table-layout: fixed;
	}
}	//	/.table

.icon-link{
	display: inline-block;
	& + &{
		margin-left: 8px;
	}
}
.icon-sm {
	width: 20px;
	opacity: .5;
	&:hover{
		cursor: pointer;
    opacity: 1;
	}
	

}

.pull-center{
	display: block;
	float: none;
	margin-left: auto;
	margin-right: auto;
}


.col{
	&-divider{
		padding: 10px @grid-gutter-width/2;
		display: inline-flex;
		&:after{
			content: "";
			flex: 1 1 auto;
			width: 1px;
			border-right:1px solid #ccc;
		}
		
	}
}

.panel-group{
	.panel{
		border-radius: 0;
		.panel-heading{
			border-radius: 0;
			padding: 0;
			.panel-title{
				a{
					position: relative;
					display: block;
					padding: 15px 60px 15px 15px;
					transition: .2s ease all;
					&, &:hover, &:focus{
						text-decoration: none;
					}
					&:hover{
						background-color: rgba(255,255,255,.4);
					}
					.pull-right{
						font-style: italic;
						font-size: .85em;
					}
					&:after{
						transition: .3s ease all;
						transform-origin: center center;
						content: "\f107";
						font-family: FontAwesome;
						font-size: 18px;
						font-weight: bold;
						position: absolute; z-index: 1;
						top: 50%; right: 15px;
						transform: translateY(-50%) rotate(0deg);
						width: 16px; height: 16px; display: inline-flex; align-items: center; justify-content: center;
					}
					&[aria-expanded="true"]:after{
						transform: translateY(-50%) rotate(180deg);
					}
				}
			}	//	/.panel-title
		}	//	/panel-heading
	}	//	/.panel
}	//	/.panel-group

.ellipsis{
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.btn{
	transition: .2s ease all;
	&-xs{
		padding-left: 10px;
		padding-right: 10px;
		line-height: 1.3;
	}
	&-sq{
		padding: 0;
		width: 21px;
    height: 21px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
		&+&{
			margin-left: 5px;
		}
	}
	
}
.row{
	&-flex{
		@media(min-width:@screen-sm-min){
			display: flex;	
		}
		
	}
}

.d{
	&-block{display: block}
	&-inline-block{display: inline-block}
	&-flex{display: flex}
	@media(min-width:@screen-sm-min){
		&-sm-block{display: block}
		&-sm-inline-block{display: inline-block}
		&-sm-flex{display: flex}
	}
	
}
.flex{
	&-column{flex-direction: column;}
	
	&-align-end{
		align-items: flex-end;
	}
	&-align-center{
		align-items: center;
	}
	&-justify-end{
		justify-content: flex-end;
	}
	&-justify-center{
		justify-content: center;
	}
}

.breadcrumb{
	.navbar-nav &{
		padding-top: 15px;
    padding-bottom: 14px;
	}
	li{
		&.active{
			background: transparent !important;
			font-weight: 600;
		}
		a{
			&,&:hover, &:focus{
				background: transparent !important;
			}
		}
	}
}

.dropzone{
	   padding: 50px;
    border: 3px dashed #ddd;
    text-align: center;
    font-weight: bold;
    color: #999;
	width: 100%;
	min-height: 140px;
	display: flex;
	align-items: center; justify-content: center;
	
}
hr{
	&.strong{
		border-top-width: 2px;
		border-color: #ddd; 
		margin-top: 25px;
		margin-bottom: 25px;
	}
} 
.x-strong{
	font-weight: 700;
}
.xx-strong{
	font-weight: 800;
}
.xxx-strong{
	font-weight: 900;
}

.h1, .h2, .h3, h1, h2, h3{margin-bottom: 25px;}

label, .label{
	font-weight: 800; font-size: .9em;
}